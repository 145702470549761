@font-face {
    font-family: "AvertaCY";
    src: local("AvertaCY"),
        url("../fonts/AvertaCY-Light.woff2") format("woff2");
    font-weight: 300;
}

@font-face {
    font-family: "AvertaCY";
    src: local("AvertaCY"),
        url("../fonts/AvertaCY-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "AvertaCY";
    src: local("AvertaCY"),
        url("../fonts/AvertaCY-Semibold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-family: "AvertaCY";
    src: local("AvertaCY"),
        url("../fonts/AvertaCY-Bold.woff2") format("woff2");
    font-weight: 700;
}