@import "./variables.scss";
@import "./breakpoints.scss";
@import "./fonts.scss";

@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/contact.scss";
@import "./components/modal.scss";
@import "./components/form.scss";

body,
html {
  visibility: hidden;
  user-select: none;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  font-family: "AvertaCY", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;

  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }

  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }

  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;

    @include media("<=phone") {
      padding: 0 16px;
    }
  }

  .v-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
  }
}



// Intro Overlay 

.intro-overlay {
  .top {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    width: 100%;
    z-index: 8;

    .overlay-top {
      position: absolute;
      height: 100%;
      width: 100vw;
      background: $black;
      bottom: 0;
      left: 0;
      right: 0;

      // &:nth-child(2) {
      //   left: 33.333%;

      //   @include media("<=tablet") {
      //     display: none;
      //   }
      // }

      // &:nth-child(3) {
      //   left: 66.666%;

      //   @include media("<=tablet") {
      //     display: none;
      //   }
      // }

      @include media("<=tablet") {
        width: 100vw;
      }


      @include media("<=phone") {}
    }
  }

  .bottom {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 8;

    .overlay-bottom {
      position: absolute;
      height: 100%;
      width: 100vw;
      background: $white;
      bottom: 0;
      right: 0;
    }
  }
}


// Loader

#loader {
  top: 50%;
  left: 50%;
  position: absolute;
  color: #ffffff;
  mix-blend-mode: difference;
  z-index: 10;
  transform: translate(-50%, -70%);

  h2 {
    font-size: 20rem;
    margin: 0;
    line-height: 20rem;

    @include media('<=tablet') {
      font-size: 10rem;
      line-height: 10rem;
    }

    @include media('<=phone') {
      font-size: 8rem;
      line-height: 8rem;
    }

  }

}