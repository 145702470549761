.modal-body {

    .contact-form {
        .form-group {
            margin: 0 0 70px 0;
            position: relative;
            overflow: hidden;

            @include media('<=phone') {
                margin: 0 0 45px 0;
            }

            .input_value {
                position: relative;
                overflow: hidden;

                input,
                textarea {
                    padding: 21px 0;
                    font-size: 35px;
                    position: relative;
                    margin: 0;
                    width: 100%;
                    z-index: 1;
                    color: #000;
                    background: 0 0;
                    border: 0;
                    border-radius: 0;
                    outline: 0;
                    resize: none;
                    font-weight: 500;
                    letter-spacing: -.03em;
                    line-height: 100%;
                    transition: all .3s, height 0s;
                    font-family: 'AvertaCY';

                    @include media('<=phone') {
                        font-size: 24px;
                    }


                    &:hover+.input_line {
                        opacity: .6;
                    }

                    &:focus+.input_line {
                        opacity: 1;
                        -webkit-transform: translateX(0);
                        -moz-transform: translateX(0);
                        -ms-transform: translateX(0);
                        -o-transform: translateX(0);
                        transform: translateX(0);
                        -webkit-transition-duration: .8s, .4s;
                        -moz-transition-duration: .8s, .4s;
                        -o-transition-duration: .8s, .4s;
                        transition-duration: .8s, .4s;
                    }

                    &::placeholder {
                        font-family: 'AvertaCY';
                        color: #ccc;
                    }
                }

                textarea {
                    overflow: hidden;
                    overflow-wrap: break-word;
                    height: 93px;
                    line-height: 120%;
                    font-family: inherit;

                }


                .input_line {
                    position: absolute;
                    height: 85px;
                    left: 0;
                    right: 0;
                    bottom: -3px;
                    width: 300%;
                    transform: translateX(-66.66%);
                    opacity: .1;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjAwIDYwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIGQ9Ik0wLDU2LjVjMCwwLDI5OC42NjYsMCwzOTkuMzMzLDBDNDQ4LjMzNiw1Ni41LDUxMy45OTQsNDYsNTk3LDQ2Yzc3LjMyNywwLDEzNSwxMC41LDIwMC45OTksMTAuNWM5NS45OTYsMCw0MDIuMDAxLDAsNDAyLjAwMSwwIj48L3BhdGg+PC9zdmc+);
                    background-position: center bottom;
                    pointer-events: none;
                    transition: transform 0s cubic-bezier(0, .25, .5, 1), opacity .4s, -webkit-transform 0s cubic-bezier(0, .25, .5, 1), -moz-transform 0s cubic-bezier(0, .25, .5, 1), -o-transform 0s cubic-bezier(0, .25, .5, 1);
                }
            }



            .input_message {
                margin: 15px 0 0 0;
                color: #eb4242;
                font-size: 16px;
                line-height: 25px;
            }

        }

        .form-submit {
            margin: 10px 0 0 0;

            .btn:disabled {
                opacity: .3;
                pointer-events: none;
            }


            .btn-send {
                position: relative;
                display: inline-block;
                height: auto;
                margin: 0;
                padding: 9px 0;
                color: inherit;
                background: 0 0;
                border: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                outline: 0 !important;
                font-family: inherit;
                font-size: 22px;
                font-weight: 600;
                letter-spacing: -.01em;
                line-height: 28px;
                text-align: inherit;
                text-transform: inherit;
                text-indent: inherit;
                text-decoration: none;
                -webkit-transition: opacity .4s;
                -o-transition: opacity .4s;
                -moz-transition: opacity .4s;
                transition: opacity .4s;
                cursor: pointer;

                @include media('<=phone') {
                    font-size: 18px;
                }


                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -15px;
                    left: -15px;
                    right: -15px;
                    bottom: -15px;
                }


                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 2px;
                    background: currentColor;
                    -webkit-transition: -webkit-transform .7s cubic-bezier(.19, 1, .22, 1);
                    transition: -webkit-transform .7s cubic-bezier(.19, 1, .22, 1);
                    -o-transition: -o-transform .7s cubic-bezier(.19, 1, .22, 1);
                    -moz-transition: transform .7s cubic-bezier(.19, 1, .22, 1), -moz-transform .7s cubic-bezier(.19, 1, .22, 1);
                    transition: transform .7s cubic-bezier(.19, 1, .22, 1);
                    transition: transform .7s cubic-bezier(.19, 1, .22, 1), -webkit-transform .7s cubic-bezier(.19, 1, .22, 1), -moz-transform .7s cubic-bezier(.19, 1, .22, 1), -o-transform .7s cubic-bezier(.19, 1, .22, 1);
                }


                &:hover {
                    text-decoration: none;
                }

                &:hover:after {
                    -webkit-transform: scaleX(0);
                    -moz-transform: scaleX(0);
                    -ms-transform: scaleX(0);
                    -o-transform: scaleX(0);
                    transform: scaleX(0);
                }


            }

        }
    }
}