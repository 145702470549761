.main {
    height: 50vh; // fallback
    height: calc(var(--vh, 1vh) * 50);
    background-color: $white;

    .row {
        flex-direction: column;
        justify-content: center;


        .wave {
            margin-top: 2.4em;
            margin-bottom: 1rem;  
            
            @include media('<=phone') {
                margin: 0;

                img {
                    width: 60px;
                }
            }

        }


        h2 {
            margin-top: 0;
            font-size: 3rem;
            line-height: 4.2rem;
            font-weight: 700;
            z-index: 10;
            color: $white;
            mix-blend-mode: difference;

            @include media('<=tablet') {
                font-size: 2.4rem;
                line-height: 3rem;
            }

            @include media('<=phone') {
                margin-top: 52px;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }



            .line {
                margin-bottom: 8px;
                height: 64px;
                position: relative;
                overflow: hidden !important;

                @include media('<=tablet') {
                    height: 48px;
                }

                @include media('<=phone') {
                    height: 32px;
                    word-wrap: break-word;
                    overflow: unset;
                }

                span {
                    position: absolute;
                }
            }
        }
    }
}