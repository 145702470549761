.contact {
    background: $black;
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: relative;

    .row {

        flex-direction: column;
        justify-content: space-evenly;

        .contact-body {

            @include media('<=phone') {
                text-align: center;
            }

            h2 {
                color: $white;
                font-size: 3rem;
                line-height: 4.2rem;
                font-weight: 700;
                mix-blend-mode: difference;
                margin: 0;

                @include media('<=phone') {
                    font-size: 1.7rem;
                    text-align: center;

                }
            }

            a {
                position: relative;
                display: inline-block;
                padding: 0 0 8px 0;
                font-weight: 300;
                text-decoration: none;
                color: $white;
                font-size: 2.4rem;
                cursor: pointer;

                @include media('<=phone') {
                    font-size: 1.4rem;
                    text-align: center;
                }

                &:hover:before {
                    -webkit-transform: scaleX(0);
                    -moz-transform: scaleX(0);
                    -ms-transform: scaleX(0);
                    -o-transform: scaleX(0);
                    transform: scaleX(0);
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background: currentColor;
                    -webkit-transition: -webkit-transform .45s cubic-bezier(.4, .22, .21, 1.04);
                    transition: -webkit-transform .45s cubic-bezier(.4, .22, .21, 1.04);
                    -o-transition: -o-transform .45s cubic-bezier(.4, .22, .21, 1.04);
                    -moz-transition: transform .45s cubic-bezier(.4, .22, .21, 1.04), -moz-transform .45s cubic-bezier(.4, .22, .21, 1.04);
                    transition: transform .45s cubic-bezier(.4, .22, .21, 1.04);
                    transition: transform .45s cubic-bezier(.4, .22, .21, 1.04), -webkit-transform .45s cubic-bezier(.4, .22, .21, 1.04), -moz-transform .45s cubic-bezier(.4, .22, .21, 1.04), -o-transform .45s cubic-bezier(.4, .22, .21, 1.04);
                }
            }


        }
    }

    .contact-footer {
        .row {
            justify-content: space-between;
            padding: 0;
            flex-direction: row;

            @include media('<=phone') {
                flex-direction: column;
            }

            .contact-footer-left {
                .contact-mail {
                    a {
                        position: relative;
                        display: inline-block;
                        padding: 0 0 5px 0;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: .00464286em;
                        text-decoration: none;
                        color: $white;

                        @include media('<=phone') {
                            font-size: 1.2rem;
                            text-align: center;
                        }

                        &:before,
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 1px;
                            background: rgba(255, 255, 255, .3);
                        }

                        &:after {
                            background: rgba(255, 255, 255, .7);
                            -webkit-transform: scaleX(0);
                            -moz-transform: scaleX(0);
                            -ms-transform: scaleX(0);
                            -o-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transition: -webkit-transform .8s cubic-bezier(.19, 1, .22, 1);
                            transition: -webkit-transform .8s cubic-bezier(.19, 1, .22, 1);
                            -o-transition: -o-transform .8s cubic-bezier(.19, 1, .22, 1);
                            -moz-transition: transform .8s cubic-bezier(.19, 1, .22, 1), -moz-transform .8s cubic-bezier(.19, 1, .22, 1);
                            transition: transform .8s cubic-bezier(.19, 1, .22, 1);
                            transition: transform .8s cubic-bezier(.19, 1, .22, 1), -webkit-transform .8s cubic-bezier(.19, 1, .22, 1), -moz-transform .8s cubic-bezier(.19, 1, .22, 1), -o-transform .8s cubic-bezier(.19, 1, .22, 1);
                        }


                        &:hover {
                            color: $white;
                        }

                        &:hover:after {
                            -webkit-transform: scaleX(1);
                            -moz-transform: scaleX(1);
                            -ms-transform: scaleX(1);
                            -o-transform: scaleX(1);
                            transform: scaleX(1);
                        }


                        span {
                            span {
                                color: #6a6a6a;
                                margin: 0 5px;
                            }
                        }
                    }
                }


                .contact-link {
                    a {
                        position: relative;
                        font-size: 14px;
                        line-height: 22px;
                        text-decoration: none;
                        color: $white;


                        &:hover:before {
                            -webkit-transform: scaleX(1);
                            -moz-transform: scaleX(1);
                            -ms-transform: scaleX(1);
                            -o-transform: scaleX(1);
                            transform: scaleX(1);
                            -webkit-transform-origin: left center;
                            -moz-transform-origin: left center;
                            -ms-transform-origin: left center;
                            -o-transform-origin: left center;
                            transform-origin: left center;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -3px;
                            height: 1px;
                            -webkit-transform: scaleX(0);
                            -moz-transform: scaleX(0);
                            -ms-transform: scaleX(0);
                            -o-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: right center;
                            -moz-transform-origin: right center;
                            -ms-transform-origin: right center;
                            -o-transform-origin: right center;
                            transform-origin: right center;
                            background: currentColor;
                            -webkit-transition: -webkit-transform .4s, -webkit-transform-origin 0s;
                            transition: -webkit-transform .4s, -webkit-transform-origin 0s;
                            -o-transition: -o-transform .4s, -o-transform-origin 0s;
                            -moz-transition: transform .4s, transform-origin 0s, -moz-transform .4s, -moz-transform-origin 0s;
                            transition: transform .4s, transform-origin 0s;
                            transition: transform .4s, transform-origin 0s, -webkit-transform .4s, -moz-transform .4s, -o-transform .4s, -webkit-transform-origin 0s, -moz-transform-origin 0s, -ms-transform-origin 0s, -o-transform-origin 0s;
                        }
                    }
                }

                @include media('<=phone') {
                    margin-bottom: 2rem;
                    text-align: center;
                }
            }

            .contact-footer-right {
                max-width: 300px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                @include media('<=phone') {
                    max-width: unset;
                }

                a {
                    svg {
                        width: 25px;
                        fill: #4c4c4c;

                        @include media('<=phone') {
                            width: 18px;
                        }
                    }


                }
            }

        }
    }


    .copyright-section {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #4c4c4c;
            font-size: 0.8rem;
            height: 100%;
        }

    }

}