#modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 11;

    .modal-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal {
            width: 90%;
            height: 90%;
            background: #fff;
            margin: auto;

            .row {
                flex-direction: column;

                .modal-header {

                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h1 {
                        font-size: 3rem;
                        font-weight: 400;
                        letter-spacing: -.05em;
                        -webkit-perspective: 600px;
                            -moz-perspective: 600px;
                            perspective: 600px;

                        @include media('<=tablet') {
                            font-size: 2.0rem;
                        }

                        @include media('<=phone') {
                            font-size: 32px;
                            letter-spacing: -.03em;
                        }
                    }

                    .close-btn {
                        position: relative;
                        top: -1px;
                        display: inline-block;
                        width: 25px;
                        line-height: 25px;
                        vertical-align: middle;
                        -webkit-transition: color .2s;
                        -o-transition: color .2s;
                        -moz-transition: color .2s;
                        transition: color .2s;

                        cursor: pointer;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: -20px;
                            right: -20px;
                            left: -20px;
                            bottom: -20px;
                        }

                        span {
                            position: relative;
                            display: block;
                            width: 100%;
                            height: 2px;
                            margin: 5px 0;
                            top: 0;
                            z-index: 1;
                            -webkit-backface-visibility: hidden;
                            -moz-backface-visibility: hidden;
                            backface-visibility: hidden;
                            background: currentColor;
                            -webkit-transition: top .3s .3s, -webkit-transform .3s;
                            transition: top .3s .3s, -webkit-transform .3s;
                            -o-transition: top .3s .3s, -o-transform .3s;
                            -moz-transition: top .3s .3s, transform .3s, -moz-transform .3s;
                            transition: top .3s .3s, transform .3s;
                            transition: top .3s .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s, -o-transform .3s;

                            &:first-child {
                                -webkit-animation: cb-btn_menu-open-top .75s;
                                -moz-animation: cb-btn_menu-open-top .75s;
                                -o-animation: cb-btn_menu-open-top .75s;
                                animation: cb-btn_menu-open-top .75s;
                                top: 4px;
                                -webkit-transform: rotate(45deg) scaleX(1);
                                -moz-transform: rotate(45deg) scaleX(1);
                                -ms-transform: rotate(45deg) scaleX(1);
                                -o-transform: rotate(45deg) scaleX(1);
                                transform: rotate(45deg) scaleX(1);
                                -webkit-transition-duration: 0s, 0s;
                                -moz-transition-duration: 0s, 0s;
                                -o-transition-duration: 0s, 0s;
                                transition-duration: 0s, 0s;
                            }

                            &:last-child {
                                -webkit-animation: cb-btn_menu-open-bottom .75s;
                                -moz-animation: cb-btn_menu-open-bottom .75s;
                                -o-animation: cb-btn_menu-open-bottom .75s;
                                animation: cb-btn_menu-open-bottom .75s;
                                top: -3px;
                                -webkit-transform: rotate(-45deg) scaleX(1);
                                -moz-transform: rotate(-45deg) scaleX(1);
                                -ms-transform: rotate(-45deg) scaleX(1);
                                -o-transform: rotate(-45deg) scaleX(1);
                                transform: rotate(-45deg) scaleX(1);
                                -webkit-transition-duration: 0s, 0s;
                                -moz-transition-duration: 0s, 0s;
                                -o-transition-duration: 0s, 0s;
                                transition-duration: 0s, 0s;
                            }

                        }
                    }

                }



            }

        }
    }

}